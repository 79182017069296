export default function memberData() {
  return [
    {
      name: "Jordan Yatckoske",
      photoUrl: "/laptop-employee.png",
    },
    {
      name: "Evan Amundson",
      photoUrl: "/laptop-employee.png",
    },
    {
      name: "Gansaikhan Shur",
      photoUrl: "/laptop-employee.png",
    },
    {
      name: "Tyler Malmburg",
      photoUrl: "/laptop-employee.png",
    },
  ];
}
